body {
  margin: 0;
  font-family: Roboto, Helvetica, Arial, sans-serif;
}

.login-button {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  background-color: #2b579a; /* Microsoft blue */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.login-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  height: 100%; /* Full height of the container */
}

.login-button:disabled {
  background-color: #a0a0a0;
  cursor: not-allowed;
}

.microsoft-logo {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.container {
  bottom: 0px !important;
  left: 0px !important;
  margin: 0px !important;
  overflow: hidden !important;
  padding: 0px !important;
  position: absolute !important;
  right: 0px !important;
  top: 0px !important;
  width: 100% !important;
}

.login-page {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.login-vert-margin {
  flex: 1;
}

.login-left-max {
  min-width: 400px;
}

.login-form-container {
  margin-top: 150px;
}

.login-buttons button {
  margin-top: 5px;
}

.continue-as-user {
  margin-top: 15px;
}

.current-user-continue {
  color: #007bff;
}

.new-user-form {
  display: none;
}

.di-logo {
  margin: 0 auto;
  width: 64px;
}

.di-logo > img {
  width: 100%;
}

.welcome-text {
  font-size: 34px;
  font-weight: 700;
  padding-top: 10px;
  text-align: center;
}

.login-form-buttons > button,
.login-form-buttons > button:hover {
  background-color: #007bff;
}

.login-media {
  background: url("https://images.ctfassets.net/f1mukqwjr3b7/1HOX58cP8sR4TUbKLXQyT9/4c7a101664bc8f7cbe9177378267c762/defaultVideoBackground.jpg")
    center top / cover no-repeat;
  height: 100%;
}

.welcome-text-2 {
  color: #ffffff;
  font-size: 30px;
  font-weight: 700;
  padding: 100px 0 80px;
  text-align: center;
}

.login-media-container {
  margin: 0 auto;
  width: 900px;
}

.di-employee-login {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 30px;
}
